import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userUrl: "", //用户登录
    expertUrl: "", //专家登录
    picketageExpertUrl: "", //定标系统
    projectInfoServe: {}, //项目信息
    registerUrl: "", //用户注册
    websiteUrl: "https://hb.bafanghaoda.com/dzbh/website", //保函办理
    ggzUrl:
      "https://hebcaonline.hebca.com/Hebca/certbussconduct/toPage.action?projectType=bfhztb&toPage=main_ggzy_2&onlineType=4", //CA办理
    scrollbarLoading: false, //滚动条加载状态
    isPhone: null, //是否手机端
  },
  mutations: {
    setProjectInfoServe(state, value) {
      state.projectInfoServe = value;
      state.userUrl = value.homePagePath + "/business/login";
      state.expertUrl = value.evaluationPath + "/evaluationPackageIndex";
      state.picketageExpertUrl =
        value.homePagePath + "/picketage/evaluationPackageIndex";
      state.registerUrl = value.homePagePath + "/business/register";
    },
  },
  actions: {
    setProjectInfoServe({ commit }, value) {
      commit("setProjectInfoServe", value);
    },
  },
});
